import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import { colors, md } from '../common'

export default () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Wrap>
      <h1 css={`text-align:center;color:${colors.green}`}>UNDERWOOD’S PRIVACY POLICY</h1>
      <br /><br />
      <p>Underwood Jewelers knows that you are concerned about how your personal information is used and shared, and we take your privacy seriously. We are committed to giving you transparency of our privacy practices and control over your data. Please read the following to learn more about our privacy policy. By using the Service you agree to this Privacy Policy (the “Policy”).</p>
      <p>By visiting or using the UNDERWOOD’S website (www.underwoodjewelers.com ), UNDERWOOD’S services and applications, and any other linked pages, features, or content offered from time to time by UNDERWOOD’S in connection therewith (collectively, the “Service”), you, the user (“you” or “User”) acknowledge that you accept the practices and policies outlined in this Policy.</p>
      <p>If you choose to use the Service or visit the Site, your visit and any dispute over privacy is subject to this Policy and the Service’s Terms and Conditions, including limitations on damages, jurisdiction of disputes and application of the law of the State of Florida.</p>
      <p>UNDERWOOD’S will only share your personally identifiable information according to the terms of this Policy or when required by law. Please review this before you use UNDERWOOD’S Site or Service.</p>
      <p>While navigating on the Rolex section of our website, you may interact with an embedded website from <a href="https://www.rolex.com">www.rolex.com</a>. In such case, <a href="https://www.rolex.com/footer/privacy-notice.html">Privacy Notice</a> and <a href="https://www.rolex.com/footer/cookies.html">Cookies Policy</a> of <a href="https://www.rolex.com">www.rolex.com</a> are sole applicable.</p>
      <p>Terms and Conditions: Please also visit UNDERWOOD’S Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of the Site and Service</p>
      <h3>What Information UNDERWOOD’S Collects, Where It Is Collected, and How It Is Used</h3>
      <p>In order to provide you the best value possible using the Service, UNDERWOOD’S collects information from different sources.</p>
      <h3>Personally Identifiable Information</h3>
      <p>In order for you to purchase products that UNDERWOOD’S offers, UNDERWOOD’S may require you to provide UNDERWOOD’S with certain personal information, as appropriate. You may be asked to enter your: name, email address, payment information, and billing and shipping address.</p>
      <p>On some occasions, to provide you better Service and opportunities, UNDERWOOD’S may combine the information UNDERWOOD’S collects from you with information about you that UNDERWOOD’S receives from other online and offline sources, including other products and Service offered by UNDERWOOD’S.</p>
      <p>Listed below are places on the Services where UNDERWOOD’S collects personal information and details on how the information is used:</p>
      <h3>Product Sales</h3>
      <p>Although Users are not required to register or pay any fee in order to browse the Product offered through the Site and Service, UNDERWOOD’S requires Users to provide personal information in order to buy Product from the Site. UNDERWOOD’S utilizes on online ecommerce site as well as third party merchant services to facilitate the Product sales (the “Sales”). Underwood’s uses the services of Authorize.net to collect payment. Please check these third parties’ websites for their privacy policies. In order to facilitate the sales process, UNDERWOOD’S and/or merchant services will collect and use certain information such as your name, email address, billing address, shipping address, phone number and credit card information to process your purchase. Credit cards and billing information are used for the sole purpose of carrying out the transaction.</p>
      <h3>Mailing List</h3>
      <p>The mailing list is processed through Mailchimp. As will all third parties, please check their privacy policies before sharing your information. The email address you provide will only be used to send you information and updates pertaining to the Service. If you decide to opt-in to UNDERWOOD’S mailing list, you will receive emails that may include company news, updates, related art or service information, etc. You can unsubscribe from receiving UNDERWOOD’S’s emails at any time. You can unsubscribe by clicking on the “Unsubscribe” link in the footer of any email and following the instructions.</p>
      <h3>Contact Us Submission</h3>
      <p>UNDERWOOD’S requests personally identifiable information collected from you to contact UNDERWOOD’S. To contact UNDERWOOD’S, you must enter your name, email and message through the Contact Us Form. You may also provide a Phone Number. This information is used to allow UNDERWOOD’S to contact you, improve content of the Service, customize the content you see, and any other customer service that you may request from us.</p>
      <p></p>
      <h3>Social Plugins</h3>
      <p>UNDERWOOD’S utilizes Social Plugins to further connect with Users like Facebook, Twitter and Pinterest. For more information about those Social Plugins we encourage you to review their policies.</p>
      <p>To illustrate, when you “Like us” through the Facebook social plugin, your profile picture as well as your name may appear on the Facebook plugin on our Site. Any other information you share is governed by your Facebook privacy settings.</p>
      <p>We encourage you to review your privacy settings before interacting with us through the Social Plugins on the UNDERWOOD’S Site.</p>
      <h3>Children</h3>
      <p>Our Site and Service are geared towards adults. In no event should the Site be used by a child. UNDERWOOD’S does not knowingly collect information from children under the age of 13 nor does UNDERWOOD’S share such information with third parties. Children under the age of 18 may use the Service only with the involvement of a parent or guardian.</p>
      <h2>Non-Personally Identifiable Information</h2>
      <h3>Analytics</h3>
      <p>The Site uses Google Analytics to track user information. While we will outline the data UNDERWOOD’S by Google Analytics, as with all third party applications, please refer to their privacy policy here.</p>
      <p>Although such information is not personally identifiable, it may be possible to determine from an IP address a visitor’s Internet Service Provider and the geographic location of the visitor’s point of connectivity as well as other statistical usage data. Your IP address and other non-personally identifiable information also may be used to gather broad demographic information and to recognize customer traffic patterns and Site usage trends. This information helps us in selecting types of Product, and in developing the design and layout of the Site.</p>
      <p>We also may share aggregated statistical data and other non-personally identifiable information with our current and potential business partners, advertisers, sponsors and service providers. UNDERWOOD’S will not, intentionally, disclose aggregate information to a partner in a manner that could identify you personally.</p>
      <h3>Cookies</h3>
      <p>While navigating on the Rolex section of our website, you may interact with an embedded website from <a href="https://www.rolex.com">www.rolex.com</a>. In such case, Privacy Notice and Cookies Policy of <a href="https://www.rolex.com">www.rolex.com</a> are sole applicable.</p>
      <p>UNDERWOOD’S uses cookies to help UNDERWOOD’S remember visitors and process User’s request, understand and save User’s preferences for future visits and compile aggregate data about site traffic and site interaction so that UNDERWOOD’S can offer better site experiences and tools in the future.</p>
      <p>You can choose not to allow cookies by changing the settings of your browser. If you choose not to accept cookies, your experience navigating the Service, including the Site, may be reduced and not work as intended.</p>
      <p>While navigating on the Rolex section of our website, you may interact with an embedded website from <a href="https://www.rolex.com">www.rolex.com</a>. In such case, <a href="https://www.rolex.com/footer/privacy-notice.html">Privacy Notice</a> and <a href="https://www.rolex.com/footer/cookies.html">Cookies Policy</a> of <a href="https://www.rolex.com">www.rolex.com</a> are sole applicable.</p>
      <h3>Information Sharing and Disclosure</h3>
      <p>Any of the information UNDERWOOD’S collects from you may be used in one of the following ways:</p>
      <ul><li>1) To personalize your experience (your information helps UNDERWOOD’S to better respond to your individual needs)</li>2) To improve the Site (UNDERWOOD’S continually strives to improve the Site offerings based on the information and feedback UNDERWOOD’S receives from you)<li></li>3) To improve customer service (your information helps UNDERWOOD’S to more effectively respond to your customer service requests and support needs)<li></li>4) To administer a contest, promotion, survey or other Site feature<li></li>5) To send periodic emails providing product or service to you<li></li>6) to execute any activity related with the operation of UNDERWOOD’S’s business, and<li></li>7) to process your purchase.</ul>
      <p>UNDERWOOD’S won’t share your personal information with other companies, nor will we rent, sell or exchange your email address and information unless otherwise provided:</p>
      <h3>Service Providers</h3>
      <p>We work with third parties who provide services, including but not limited to website hosting, e-commerce affiliation, data analysis, emailing and other services of an administrative nature. Personally identifiable information is shared with such third parties for the purpose of providing such services.</p>
      <h3>Third Parties</h3>
      <p>We may use selected reputable third parties for marketing, targeting, partnerships and analysis purposes. Some of these third parties may be given access to some, or all, of the information that you provide to us and may use cookies or other tracking technologies in connection with providing their services. However, these companies do not have an independent right to share this information.</p>
      <p>Emails from UNDERWOOD’S and the Site itself, may contain links to other Internet websites, including sites that may or may not be owned or operated by UNDERWOOD’S. Unless otherwise explicitly stated, we are not responsible for the privacy practices or the content of such websites, including such sites’ use of any information (such as IP number, browser type or operating system) collected when email recipients or Site visitors click through links to those sites. If you visit such websites, we encourage you to become familiar with the privacy practices of those sites.</p>
      <h3>Legal Obligation to Provide Information</h3>
      <p>Subject to applicable law, we reserve the right to release information concerning any Site visitor where such visitor is believed to be in violation of his or her obligations to us, is partaking (or is suspected of partaking) in any illegal activity, in response to civil subpoenas and discovery requests, to protect the interests of UNDERWOOD’S, our customers or others, and as otherwise permitted or required by law. When possible, UNDERWOOD’S will notify you of such legal proceedings to allow you to respond and/or act on your own behalf.</p>
      <h3>Control of User Information</h3>
      <p>Users may have a right to access, modify, correct and delete the data about themselves which has been collected pursuant to their use of the site or directly through the third parties mentioned above. Control of User information is dependent on the third party and UNDERWOOD’S does not have any control of the privacy policies of the third parties used to facilitate your requests. We can only delete information if it can be directly linked to the User’s account. If Users update any of their information, UNDERWOOD’S may keep a copy of the information which Users originally provided to UNDERWOOD’S in their archives for uses documented herein.</p>
      <p>UNDERWOOD’S takes your rights seriously and encourages you to use them if you deem this to be appropriate. You may exercise these rights by emailing UNDERWOOD’S at support@Underwoodjewelers.com .</p>
      <h3>Deleting Your Personal Information</h3>
      <p>You may request to have your information deleted by UNDERWOOD’S and UNDERWOOD’S will do so within a reasonable amount of time. The non-personal information aggregated by the analytics will remain as, among other reasons, UNDERWOOD’S has no control over that information.</p>
      <h3>What Choices Do you Have?</h3>
      <p>If you do not wish to receive email or other mail from us, please indicate by unsubscribing on the emails received, by indicating so on the Contact Us Form, or by contacting us. Please note that if you do not want to receive legal notices from us, such as this Privacy Policy, those legal notices will still govern your use of the UNDERWOOD’S Site, and you are responsible for reviewing such legal notices for changes.</p>
      <p>You may contact us regarding removal of any of your information.</p>
      <h3>Security</h3>
      <p>UNDERWOOD’S implements a variety of security measures to maintain the safety of your personal information when you place an order or access your personal information.</p>
      <p>All credit card information will go through a certified third party merchant service to ensure your security.</p>
      <p>Even though UNDERWOOD’S is committed to do all in their reach to keep your information safe, UNDERWOOD’S cannot guarantee that the Information will never be disclosed in a manner that is inconsistent with this Privacy Policy. Moreover, UNDERWOOD’S is not responsible for any breach of security or for any actions of any third parties that receive the information.</p>
      <h3>Sale of the Company</h3>
      <p>As a natural consequence of the development of the company, UNDERWOOD’S could be sold to another company. In the event that UNDERWOOD’S is acquired by another company or a significant portion of UNDERWOOD’S assets are sold to another company, all of the customer information, including both financial records and consumer information that UNDERWOOD’S has collected, may be transferred to the new company.</p>
      <p></p>
      <h3>Changes to this Privacy Policy</h3>
      <p>UNDERWOOD’S may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used. If we make material changes in the way we use Personal Information, we will notify you by posting an announcement on our Site and sending you an email. Users are bound by any changes to the Privacy Policy when he or she uses the Site after such changes have been first noticed. If you have unsubscribed from all email communications from UNDERWOOD’S, we will not email you changes to the Privacy Policy. You will still, however, be subject to the Privacy Policy changes.</p>
      <p>If you are concerned about how your information is used, you should review our Policy periodically.</p>
      <h3>Questions and comments</h3>
      <p>If there are any questions or comments regarding this Privacy Policy, you may contact UNDERWOOD’S at info@Underwoodjewelers.com</p>
  </Wrap>
  <JoinUs />
</Layout>
)

const Wrap = styled.div`
  padding: 4rem;

  ul {
    list-style-type: none;
    padding: 1rem;
  }

  li {
    padding:1rem 0;
  }

  ${md} {
    padding:1rem;
  }
`
